import _core2 from "@styled-system/core";
var exports = {};
exports.__esModule = true;
exports["default"] = exports.shadow = void 0;
var _core = _core2;
var shadow = (0, _core.system)({
  boxShadow: {
    property: "boxShadow",
    scale: "shadows"
  },
  textShadow: {
    property: "textShadow",
    scale: "shadows"
  }
});
exports.shadow = shadow;
var _default = shadow;
exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule;
const _shadow = exports.shadow;
export { _shadow as shadow };